<template>
  <div>
    <div class="container-fluid" v-if="post">
      <div class="card border-0 shadow-none card-margin">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-4">
              <h4>Edit post</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="row mb-3">
                <div class="col">
                  <label for="">Post title</label>
                  <input
                    type="text"
                    v-model="post.title"
                    @input="titleUpdate"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for="">Slug</label>
                  <input
                    type="text"
                    v-model="post.slug"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Author</label>
                  <select v-model="post.author_id" class="form-control">
                    <option v-for="a in authors" :value="a.id" :key="a.id" :selected="a.id === post.author_id">
                      {{ (a.first_name + ' ' + a.last_name) }}
                    </option>
                  </select>
                </div>

                <div class="col">
                  <label for="">Category</label>
                  <select v-model="post.category_id" class="form-control">
                    <option v-for="c in categories" :value="c.id" :key="c.id" :selected="c.id === post.category_id">
                      {{ c.title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Status</label>
                  <select v-model="post.status" @change="statusChange" class="form-control">
                    <option value="draft">Draft</option>
                    <option value="published">Published</option>
                  </select>
                </div>
                <div class="col"/>
              </div>

              <vue-wysiwyg-editor v-model="post.content" class="mb-4"
                :disableFullScreen="false"></vue-wysiwyg-editor>

              <div class="row mb-4">
                <div class="col">
                  <div>
                    <label for="" class="mb-3">Featured image</label>
                    <feature-image-upload
                      v-model="post.featured_image"
                      v-bind:image="post.featured_image"
                      @unsetFeaturedImg="unsetFeaturedImg"
                    ></feature-image-upload>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <a @click="showHideSeoOpt" class="option-link">
                    <span v-if="seo_opt_show">-</span>
                    <span v-else>+</span> SEO options
                  </a>
                </div>
              </div>

              <div class="row" v-if="seo_opt_show">
                <div class="col">
                  <div class="mb-3">
                    <label for="">SEO title</label>
                    <input
                      type="text"
                      v-model="post.seo_title"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-4">
                    <label for="">SEO Description ({{ seo_desc_left }} left)</label>
                    <textarea
                      rows="3"
                      v-model="post.seo_description"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <a @click="showHideAdvOpt" class="option-link">
                    <span v-if="adv_opt_show">-</span>
                    <span v-else>+</span> Advanced options
                  </a>
                </div>
              </div>

              <div class="row" v-if="adv_opt_show">
                <div class="col">
                  <div class="mb-3">
                    <label for="">Embedded scripts</label>
                    <textarea
                      rows="3"
                      v-model="post.embedded_scripts"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-4">
                    <label for="">Ad to show</label>
                    <select id="title" v-model="post.ad_id" class="form-control">
                      <option value="" :selected="post.ad_id == null">None</option>
                      <option value="1" :selected="post.ad_id == '1'">ThreeSixtyHealth - light</option>
                      <option value="2" :selected="post.ad_id == '2'">ThreeSixtyHealth - dark</option>
                      <option value="3" :selected="post.ad_id == '3'">GutHealth - light</option>
                      <option value="4" :selected="post.ad_id == '4'">GutHealth - dark</option>
                      <option value="5" :selected="post.ad_id == '5'">HormoneHealth - light</option>
                      <option value="6" :selected="post.ad_id == '6'">HormoneHealth - dark</option>
                      <option value="7" :selected="post.ad_id == '7'">GeneticHealth - light</option>
                      <option value="8" :selected="post.ad_id == '8'">GeneticHealth - dark</option>
                      <option value="9" :selected="post.ad_id == '9'">FreeHealthConsultation - light</option>
                      <option value="10" :selected="post.ad_id == '10'">FreeHealthConsultation - dark</option>
                    </select>
                  </div>

                  <div class="mb-3">
                    <input type="checkbox" v-model="post.featured">
                    <label for="">Set featured</label>
                  </div>

                  <div class="mb-4">
                    <input type="checkbox" v-model="post.show_trusted_message">
                    <label for="">Show trusted message</label>
                  </div>
                </div>
              </div>

              <div class="row" v-if="post.status !== 'published'">
                <div class="col">
                  <button class="btn btn-success cursor-pointer align-right" @click="publish">Publish</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed bottom-0 mb-3 start-0 ms-2">
        <button
          class="btn btn-success btn-lg d-block mb-4"
          v-tooltip.right="'Preview post'"
          @click="previewPost"
          :disabled="!this.post.category_id || !this.post.slug"
        >
          <i class="far fa-eye"></i>
        </button>

        <button
          class="btn btn-success btn-lg d-block mb-4"
          v-tooltip.right="'Save changes to the post'"
          @click="savePost"
        >
          <i class="far fa-save"></i>
        </button>

        <button
          class="btn btn-outline-danger btn-lg d-block mb-4"
          v-tooltip.right="'Delete the post'"
          @click="deletePost"
        >
          <i class="far fa-trash"></i>
        </button>

        <button
          class="btn btn-outline-success btn-lg"
          v-tooltip.right="'Back to posts'"
          @click="$router.push('/posts')"
        >
          <i class="far fa-arrow-left"></i>
        </button>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import VueWysiwygEditor from "../../components/editor/Vue-Wysiwyg-Editor";
import FeatureImageUpload from "../../components/editor/partials/FeatureImageUpload";

export default {
  data() {
    return {
      // Post data
      post: {
        id: null,
        slug: "",
        author_id: null,
        category_id: null,
        title: "",
        content: "",
        embedded_scripts: "",
        seo_title: "",
        seo_description: "",
        featured_image: "",
        featured: 0,
        status: "draft",
        ad_id: 0,
        show_trusted_message: 0,
      },
      // Additional API requests
      authors: [],
      categories: [],
      // Various display vars
      adv_opt_show: 0,
      seo_opt_show: 0,
      seo_desc_max_len: 200,
      seo_desc_left: 200,
    };
  },
  watch: {
    post: {
      handler: function (val, oldVal) {
        if (val.seo_description != "") {
          this.seo_desc_left = (this.seo_desc_max_len - this.post.seo_description.length);
        } else {
          this.seo_desc_left = this.seo_desc_max_len;
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchPost() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.id)
        .then(({ data }) => {
          this.post = data;
        });
    },

    savePost() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.id,
          this.post
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },

    deletePost() {
      if (
        confirm(
          "Are you sure you wish to delete this post, this action cannot be undone?"
        )
      ) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/posts/" + this.$route.params.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$router.push("/posts");
          });
      }
    },

    previewPost() {
      // The site only checks for status on the frontpage, so
      // we can preview draft posts
      let catSlug = this.getCurrentCatSlug();
      let baseUrl = process.env.VUE_SITE_URL ? process.env.VUE_SITE_URL : 'https://amchara.com';
      if (catSlug) {
        window.open(`${baseUrl}/${catSlug}/${this.post.slug}`);
      }
    },

    getAuthors() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/posts/authors")
        .then(({ data }) => {
          this.authors = data;
        });
    },

    getCategories() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/posts/categories")
        .then(({ data }) => {
          this.categories = data;
        });
    },

    getCurrentCatSlug() {
      let catSlug = null;

      this.categories.forEach(cat => {
        if (cat.id === this.post.category_id) {
          catSlug = cat.slug;
        }
      });

      return catSlug;
    },

    publish() {
      var c = confirm(
        "You're about to publish this post. This will make it available on the front page. Are you sure?");
      if (c) {
        this.post.status = "published";
        this.savePost();
      }
    },

    showHideAdvOpt() {
      this.adv_opt_show = !this.adv_opt_show ? 1 : 0;
    },

    showHideSeoOpt() {
      this.seo_opt_show = !this.seo_opt_show ? 1 : 0;
    },

    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, "");

      // Make the string lowercase
      str = str.toLowerCase();

      // Remove accents, swap ñ for n, etc
      var from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to =
        "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      // Remove invalid chars
      str = str
        .replace(/[^a-z0-9 -]/g, "")
        // Collapse whitespace and replace by -
        .replace(/\s+/g, "-")
        // Collapse dashes
        .replace(/-+/g, "-");

      return str;
    },

    statusChange() {
      if (this.post.status === 'published') {
        var c = confirm(
          "You're about to publish this post. This will make it available on the front page. Are you sure?");
        if (!c) {
          this.post.status = 'draft';
        }
      }
    },

    titleUpdate() {
      if (this.post.title != "") {
        this.post.seo_title = this.post.title;
        this.post.slug = this.slugify(this.post.title);
      }
    },

    unsetFeaturedImg() {
      this.post.featured_image = null
    },
  },
  created() {
    this.fetchPost();
  },
  mounted() {
    this.$store.sidebarOpen = false;

    this.getAuthors();
    this.getCategories();
  },
  components: {
    Loading,
    VueWysiwygEditor,
    FeatureImageUpload
  },
};
</script>

<style>
  .card-margin {
    margin: 0 2rem;
  }

  div.ProseMirror {
    min-height: 250px;
  }

  .option-link {
    margin-right: 10px;
    text-decoration: underline;
  }

  input[type=checkbox] {
    margin-right: 10px;
  }

  .fa-eye {
    width: 1.1rem;
  }

  span[id] {
    text-decoration:underline;
    text-decoration-style: dotted;
  }

  ul {
    display: block;
    list-style-type: disc;
    padding-inline-start: 20px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
</style>